import React from 'react'

const AccessibilityStatement = () => {
  return (
    <div className="container" style={{marginTop: 20}}>
        <h1>Deklaracja dostępności</h1>
        <section id="a11y-wstep">
          <p>
            <span id="a11y-podmiot">Uniwersytet w Siedlcach</span> zobowiązuje się
            zapewnić dostępność swojej{" "}
            <span id="a11y-zakres">strony internetowej</span> zgodnie z ustawą z dnia
            4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji
            mobilnych podmiotów publicznych.
          </p>
          <p>
            Deklaracja dostępności dotyczy strony{" "}
            <a href="https://kodigo.ii.uws.edu.pl/" id="a11y-url">
              kodigo.ii.uws.edu.pl
            </a>
            .
          </p>
        </section>
        <ul>
          <li>
            Data publikacji strony internetowej:{" "}
            <time id="a11y-data-publikacja" dateTime="2014-05-13">
              13 maja 2014 r.
            </time>
          </li>
          <li>
            Data ostatniej istotnej aktualizacji:{" "}
            <time id="a11y-data-aktualizacja" dateTime="2024-11-23">
              23 listopada 2024 r.
            </time>
          </li>
        </ul>
        <h2> Stan dostępności cyfrowej</h2>
        <p id="a11y-status">
          Ta strona internetowa jest w pełni zgodna z załącznikiem do ustawy o
          dostępności cyfrowej z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
          internetowych i aplikacji mobilnych podmiotów publicznych.
        </p>
        <h2>Przygotowanie deklaracji dostępności</h2>
        <ul>
          <li>
            Data sporządzenia deklaracji:{" "}
            <time id="a11y-data-sporzadzenie" dateTime="2025-03-14">
              14 marca 2025
            </time>
          </li>
          <li>
            Data ostatniego przeglądu deklaracji:{" "}
            <time id="a11y-data-przeglad" dateTime="2025-03-14">
              14 marca 2025
            </time>
          </li>
        </ul>
        <p>
          Deklarację sporządziliśmy na podstawie samooceny w oparciu o{" "}
          <a href="https://www.gov.pl/attachment/1a3e2bb5-6d60-4897-ac2f-07a8e91e70ed">
            listę kontrolną do badania dostępności cyfrowej strony internetowej v. 2.2
            (docx, 0,12MB)
          </a>
          .
        </p>
        <h2> Skróty klawiszowe </h2>
        <p>
          Na stronie internetowej można korzystać ze standardowych skrótów
          klawiaturowych.
        </p>
        <h2> Informacje zwrotne i dane kontaktowe </h2>
        <p>
          Wszystkie problemy z dostępnością cyfrową tej strony internetowej możesz
          zgłosić do <span id="a11y-kontakt">Marcin Stępniak</span> — mejlowo{" "}
          <a id="a11y-email" href="mailto:marcin.stepniak@uws.edu.pl">
            marcin.stepniak@uws.edu.pl
          </a>{" "}
          lub telefonicznie{" "}
          <a href="tel:+48256431154" id="a11y-telefon">
            +48256431154
          </a>
          .
        </p>
        <p>
          Każdy ma prawo wystąpić z żądaniem zapewnienia dostępności cyfrowej tej
          strony internetowej lub jej elementów.
        </p>
        <p>Zgłaszając takie żądanie podaj:</p>
        <ul>
          <li>swoje imię i nazwisko,</li>
          <li>swoje dane kontaktowe (np. numer telefonu, e-mail),</li>
          <li>
            dokładny adres strony internetowej, na której jest niedostępny cyfrowo
            element lub treść,
          </li>
          <li>
            opis na czym polega problem i jaki sposób jego rozwiązania byłby dla
            Ciebie najwygodniejszy.
          </li>
        </ul>
        <p>
          Na Twoje zgłoszenie odpowiemy najszybciej jak to możliwe, nie później niż w
          ciągu 7 dni od jego otrzymania.
        </p>
        <p>
          Jeżeli ten termin będzie dla nas zbyt krótki poinformujemy Cię o tym. W tej
          informacji podamy nowy termin, do którego poprawimy zgłoszone przez Ciebie
          błędy lub przygotujemy informacje w alternatywny sposób. Ten nowy termin nie
          będzie dłuższy niż 2 miesiące.
        </p>
        <p>
          Jeżeli nie będziemy w stanie zapewnić dostępności cyfrowej strony
          internetowej lub treści, wskazanej w Twoim żądaniu, zaproponujemy Ci dostęp
          do nich w alternatywny sposób.
        </p>
        <section id="a11y-procedura">
          <h3> Obsługa wniosków i skarg związanych z dostępnością </h3>
          <p>
            Jeżeli w odpowiedzi na Twój wniosek o zapewnienie dostępności cyfrowej,
            odmówimy zapewnienia żądanej przez Ciebie dostępności cyfrowej, a Ty nie
            zgadzasz się z tą odmową, masz prawo złożyć skargę.
          </p>
          <p>
            Skargę masz prawo złożyć także, jeśli nie zgadzasz się na skorzystanie z
            alternatywnego sposobu dostępu, który zaproponowaliśmy Ci w odpowiedzi na
            Twój wniosek o zapewnienie dostępności cyfrowej.
          </p>
          <p>
            Ewentualną skargę złóż listownie lub mailem do pełnomocnika Rektora –
            koordynatora ds. dostępności jako osoby nadzorującej na adres::
          </p>
          <ul>
            <li>
              Dr Monika Niedziółka – pełnomocnik Rektora – koordynator ds. dostępności
            </li>
            <li>
              Centrum Wsparcia Osób z Niepełnosprawnościami
              <br />
              ul. ks. J. Popiełuszki 9<br />
              08-110 Siedlce
              <br />
              tel. 25 643 17 24
              <br />
            </li>
            <li>
              mejl:{" "}
              <a href="mailto:monika.niedziolka@uws.edu.pl">
                {" "}
                monika.niedziolka@uws.edu.pl
              </a>
              .
            </li>
          </ul>
          <a href="https://www.gov.pl/web/gov/zloz-wniosek-o-zapewnienie-dostepnosci-cyfrowej-strony-internetowej-lub-aplikacji-mobilnej">
            Pomocne mogą być informacje, które można znaleźć na rządowym portalu
            gov.pl
          </a>
          .<p />
          <p>
            Możesz także poinformować o tej sytuacji{" "}
            <a href="https://bip.brpo.gov.pl/"> Rzecznika Praw Obywatelskich</a> i
            poprosić o interwencję w Twojej sprawie.
          </p>
        </section>
        <h2>Pozostałe informacje</h2>
        <section id="a11y-aplikacje">
          <h3>Aplikacje mobilne</h3>
          <p>Uniwersytet w Siedlcach udostępnia następujące aplikacje mobilne:</p>
          <ul>
            <li>
              <a href="https://play.google.com/store/apps/details?id=pl.edu.uph.mobilny">
                Mobilny USOS UPH
              </a>{" "}
              — sprawdź
              <a href="https://www.uws.edu.pl/kontakt/deklaracja-dostepnosci-mobilny-usos">
                {" "}
                deklarację dostępności tej aplikacji.
              </a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=pl.prolib.uph">
                UwS Siedlce - mPROLIB
              </a>{" "}
              — sprawdź
              <a href="https://www.uws.edu.pl/kontakt/deklaracja-dostepnosci-mprolib">
                {" "}
                deklarację dostępności tej aplikacji.
              </a>
            </li>
          </ul>
        </section>
        <section id="a11y-architektura">
          <h3>Dostępność architektoniczna</h3>
          <h4>Budynki Uniwersytetu w Siedlcach</h4>
          <ul>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_bg.docx"
              >
                Deklaracja dostępności budynku Biblioteki Głównej przy ul. Popiełuszki
                9.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_csir.docx"
              >
                Deklaracja dostępności budynku Centrum Sportu i Rekreacji przy ul. 3
                Maja 54.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ds1.docx"
              >
                Deklaracja dostępności budynku Domu Studenta nr. 1 przy ul. 3 Maja 49.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ds2.docx"
              >
                Deklaracja dostępności budynku Domu Studenta nr. 2 przy ul. Żytniej
                17/19.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ds4.docx"
              >
                Deklaracja dostępności budynku Domu Studenta nr. 4 przy ul. Żytniej
                17/19.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ds5.docx"
              >
                Deklaracja dostępności budynku Domu Studenta nr. 5 przy ul. Żytniej
                17/19.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ib.docx"
              >
                Deklaracja dostępności budynku Instytutu Nauk Biologicznych przy ul.
                Prusa 14.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ii.docx"
              >
                Deklaracja dostępności budynku Instytutu Informatyki przy ul.
                Sienkiewicza 51.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ogonki.docx"
              >
                Deklaracja dostępności budynku Ośrodka Wypoczynkowego w Ogonkach, ul.
                Sztynorcka 5.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_oj.docx"
              >
                Deklaracja dostępności budynku Ośrodka Jeździeckiego przy ul. 3
                Kazimierzowskiej 3.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_palac.docx"
              >
                Deklaracja dostępności budynku Pałacu Ogińskich przy ul. Konarskiego
                2.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_wanz.docx"
              >
                Deklaracja dostępności budynku Wydziały Agrobioinżynierii i Nauk o
                Zwierzętach oraz Wydziału Nauk Medycznych i Nauk o Zdrowiu przy ul.
                Prusa 14.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_wnsp.docx"
              >
                Deklaracja dostępności budynku Wydziału Nauk Ścisłych i Przyrodniczych
                przy ul. 3 Maja 54.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ws_wh.docx"
              >
                Deklaracja dostępności budynku Wydziału Nauk Społecznych przy ul.
                Żytniej 39.
              </a>
            </li>
            <li>
              <a
                id="a11y-architektura-url"
                href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_CSM.docx"
              >
                Deklaracja dostępności budynku Centrum Symulacji Medycznych przy ul.
                Bema 1a.
              </a>
            </li>
          </ul>
        </section>
        <section id="a11y-komunikacja">
          <h3>Dostępność komunikacyjno-informacyjna</h3>
          <p>Dostępni są tłumacze polskiego języka migowego (PJM):</p>
          <ul>
            <li>
              <a href="https://www.uws.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_tlumacza_jezyka_migowego.docx">
                Pobierz dane kontaktowe tłumaczy języka migowego
              </a>
            </li>
          </ul>
        </section>
      </div>
  )
}

export default AccessibilityStatement